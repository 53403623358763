import Big from "big.js"

String.prototype.toBig = function () {
  return Big(this.replace(/,/g, ''))
}

Number.prototype.toBig = function () {
  return Big(this)
}

Number.prototype.formatDateTime = function() {
  return new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(this)
}

Big.prototype.toBig = function () {
  return this
}