import {initStore, getStore} from '~/lib/livestores'
import { derived } from "svelte/store";
import Big from 'big.js'
import { BROWSER } from 'esm-env'

export const orders = initStore("orders", [])
export const orderBooks = getStore("order_books", {});

export const buys = initStore("buys", {})
export const sells = initStore("sells", {})
export const deposits = initStore("deposits", {})
export const positions = initStore("positions", {})

const prices = initStore('prices', {})
const historicValues = initStore('historic_values', [])

export const balances = derived(positions, function($positions) {
  return Object.entries($positions).reduce(($balances, [symbol, position]) => {
    $balances[symbol] = position.amount;
    return $balances;
  }, {})
})

export const activeOrders = derived(orders, function($orders) {
  return $orders.filter(order => !order.cancelled_at && !order.completed)
})



// if the market is not moving, add new price
// points so the graph moves forward
if (BROWSER) setInterval(function() {
  prices.update(function($prices) {
    Object.keys($prices).forEach(symbol => {
      const lastPrice = $prices[symbol].at(-1)
      if (!lastPrice) return
      if (Date.now() - lastPrice[0] < 60000) return;
      $prices[symbol].push([Date.now(), lastPrice[1]]);
    });
    return $prices;
  })
}, 10000)

export const currentPrices = derived(prices, function($prices) {
  const $currentPrices = Object.keys($prices).reduce((acc, symbol) => {
    acc[symbol] = $prices[symbol].at(-1)[1];
    return acc;
  }, {"usd": 1});
  return $currentPrices;
})

export const unrealizedPnls = derived([positions, currentPrices], function([$positions, $currentPrices]) {
  let totalPnl = Big(0);
  let totalInvested = Big(0);

  const result = Object.entries($positions)
    .filter(([symbol, position]) => symbol !== "usd")
    .reduce(($unrealizedPnls, [symbol, position]) => {
    const pnl = Big(position.bought).times(Big($currentPrices[symbol]).minus(position.average_buy_price));
    const invested = Big(position.bought).times(position.average_buy_price);

    totalPnl = totalPnl.plus(pnl);
    totalInvested = totalInvested.plus(invested);

    $unrealizedPnls[symbol] = {
      total: pnl,
      percent: parseFloat(position.average_buy_price) === 0 || invested.eq(0)
        ? null
        : pnl.div(invested).times(100),
    }
    return $unrealizedPnls;
  }, {})

  result._total = totalPnl;
  result._totalPercent = totalInvested.eq(0) ? null : totalPnl.div(totalInvested).times(100);

  return result;
})



export const lockedInOrders = derived(activeOrders, function($activeOrders) {
  return $activeOrders.reduce(($lockedInOrders, order) => {
    $lockedInOrders[order.bid_symbol] ||= Big(0)
    $lockedInOrders[order.bid_symbol] = $lockedInOrders[order.bid_symbol].plus(order.remaining_bid_amount)
    return $lockedInOrders
  }, {"usd": Big(0)})
  
})



export const availableBalances = derived([balances, lockedInOrders], function ([$balances, $lockedInOrders]) {
  return Object.entries($balances).reduce(($availableBalances, [symbol, balance]) => {
    $availableBalances[symbol] = Big(balance).minus($lockedInOrders[symbol] || 0)
    return $availableBalances
  }, {"usd": Big(0)})
})

export const bagValue = derived([balances, currentPrices], function([$balances, $currentPrices]) {
  return Object.entries($balances).reduce(($total, [symbol, balance]) => {
    const price = symbol == "usd" ? 0 : $currentPrices[symbol] || 0;
    return $total.plus(Big(balance).times(price));
  }, Big(0));
})

// thir updates the graph on the bag page
bagValue.subscribe(function(value) {
  historicValues.update(function($historicValues) {
    const v = value.toNumber()
    if (v > 0) $historicValues.push([Date.now(), v])
    return $historicValues
  })
})

export const totalValue = derived([balances, currentPrices], function([$balances, $currentPrices]) {
  return Object.entries($balances).reduce((total, [symbol, balance]) => {
    const price = $currentPrices[symbol] || 0;
    return total.plus(Big(balance).times(price));
  }, Big(0));
})